import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import { Breakpoint, colorPalette, commonStyles, fontFamily, fontSize, spacing } from '../../../config/styles';
import Container from '../../Container';
import ButtonLink from '../../ButtonLink';
import { useTranslation } from '../../../hooks/useTranslation';
import { Sections } from '../../../config/constants';
import { isDeLanguage } from '../../../utils/urls';

const styles = {
  section: css`
    background-color: ${colorPalette.white};
    padding: ${spacing['80']} 0;
  `,
  heading: css`
    ${commonStyles.h1}
    margin-bottom: ${spacing['120']};
    margin-bottom: ${spacing['80']};
  `,
  blogPost: (isOdd: boolean) => css`
    display: flex;
    gap: ${spacing['50']};
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: ${spacing['60']};
    }

    ${isOdd &&
    css`
      flex-direction: row-reverse;
      text-align: right;
    `}

    ${Breakpoint.mobile} {
      flex-direction: column;
    }
  `,
  itemTitle: css`
    font-size: ${fontSize['48']};
    font-family: ${fontFamily.freightText};
    color: ${colorPalette.main};
    margin: 0;
  `,
  itemText: css`
    margin: ${spacing['20']} 0;
    ${commonStyles.basicText}
    font-size: ${fontSize['30']};
  `,

  blockWrapper: css`
    width: 40%;
    ${Breakpoint.mobile} {
      width: 100%;
    }
  `,
};
const BlogSection = () => {
  const t = useTranslation();
  const blogPosts = isDeLanguage()
    ? [
        {
          renderPicture: () => {
            return <StaticImage src="../../../images/blog/blog-1.png" alt="So funktioniert Splint" />;
          },
          title: 'So funktioniert Splint',
          description:
            'Du bist also jemand, der besonders gut über etwas Bescheid wissen will, bevor du damit loslegst? Kein Ding! Hier findest du die Fragen, die uns besonders oft gestellt werden.',
          link: '/blog/so-funktioniert-splint',
        },
        {
          renderPicture: () => {
            return (
              <StaticImage
                src="../../../images/blog/blog-2.png"
                alt="3 Gründe, um in alternative Anlagen zu investieren"
              />
            );
          },
          title: '3 Gründe, um in alternative Anlagen zu investieren',
          description:
            '«Aktien, Obligationen, Kryptowährungen, Fonds, Vorsorgelösungen… und jetzt sagt ihr mir, ich soll mich nochmal über etwas informieren?» Ja, tun wir. Aus guten Gründen. Drei sogar.',
          link: '/blog/3-grunde-um-in-alternative-anlagen-zu-investieren',
        },
      ]
    : [
        {
          renderPicture: () => {
            return <StaticImage src="../../../images/blog/blog-1.png" alt="How Splint works" />;
          },
          title: 'How Splint works',
          description:
            "So you’re somebody who really wants to be sure of something before you get involved with it? No problem! Here you'll find the questions we get asked most often.",
          link: '/en/blog/how-splint-works',
        },
        {
          renderPicture: () => {
            return (
              <StaticImage
                src="../../../images/blog/blog-2.png"
                alt="3 reasons to get started with alternative investments"
              />
            );
          },
          title: '3 reasons to get started with alternative investments',
          description:
            '"Stocks, bonds, cryptocurrencies, funds, retirement solutions... and now you\'re telling me to read up on something again?" Yes, we do. For good reasons. Three, in fact.',
          link: '/en/blog/3-reasons-to-get-started',
        },
      ];
  return (
    <section css={styles.section} id={Sections.blogSection}>
      <Container>
        <h1 css={styles.heading}>{t('blog_section_title')}</h1>
        <div>
          {blogPosts.map((item, index) => {
            const isOdd = index % 2 !== 0;
            return (
              <div css={styles.blogPost(isOdd)} key={index}>
                <div css={styles.blockWrapper}>{item.renderPicture()}</div>
                <div css={styles.blockWrapper}>
                  <h3 css={styles.itemTitle}>{item.title}</h3>
                  <div css={styles.itemText}>{item.description}</div>
                  <ButtonLink href={item.link} label={t('common_btn_read_more')} internal={true} />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default BlogSection;
