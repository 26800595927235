import { css } from '@emotion/react';
import React from 'react';
import { Breakpoint, containerSpacing, containerWidth } from '../../config/styles';

const styles = {
  content: css`
    max-width: ${containerWidth};
    margin: 0 auto;

    ${Breakpoint.tablet} {
      margin: 0 ${containerSpacing};
    }
  `,
};

interface ContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  return <div css={styles.content}>{children}</div>;
};

export default Container;
