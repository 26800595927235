import { css } from '@emotion/react';
import { Breakpoint, colorPalette, commonStyles, spacing } from '../../../config/styles';
import Container from '../../Container';
import { StaticImage } from 'gatsby-plugin-image';
import ButtonLink from '../../ButtonLink';
import { useTranslation } from '../../../hooks/useTranslation';
import { getFaqLink } from '../../../utils/urls';

const styles = {
  section: css`
    background-color: ${colorPalette.lightBlue};
    padding-top: ${spacing['80']};
  `,
  heading: css`
    ${commonStyles.h1}
    margin-bottom: ${spacing['80']};
  `,
  text: css`
    display: block;
    padding-bottom: ${spacing['40']};
    ${commonStyles.basicText}
  `,
  wrapper: css`
    display: flex;
    gap: ${spacing['60']};
  `,
  imageWrapper: css`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    ${Breakpoint.mobile} {
      display: none;
    }
  `,
  textWrapper: css`
    width: 60%;
    padding-bottom: ${spacing['80']};

    ${Breakpoint.mobile} {
      width: 100%;
    }
  `,
};
const FaqSection = () => {
  const t = useTranslation();

  return (
    <section css={styles.section}>
      <Container>
        <div css={styles.wrapper}>
          <div css={styles.imageWrapper}>
            <StaticImage src="../../../images/hand-with-phone.png" alt={t('faq_section_title')} />
          </div>
          <div css={styles.textWrapper}>
            <h1 css={styles.heading}>{t('faq_section_title')}</h1>
            <div css={styles.text}>{t('faq_section_text')}</div>
            <ButtonLink href={getFaqLink()} label={t('faq_section_button')} internal={true} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FaqSection;
