import { css } from '@emotion/react';
import { colorPalette } from '../../../config/styles';
import ReactPlayer from 'react-player/youtube';
import { VIDEO_URL } from '../../../config/constants';

const styles = {
  section: css`
    background-color: ${colorPalette.lightBlue};
  `,
  videoWrapper: css`
    position: relative;
    padding-top: 56.25%;
  `,
  player: css`
    position: absolute;
    top: 0;
    left: 0;

    &:after {
      /* Let's hide all hover events on video */
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  `,
};
const HeroSection = () => {
  return (
    <section css={styles.section}>
      <div css={styles.videoWrapper}>
        <ReactPlayer
          css={styles.player}
          muted={true}
          url={VIDEO_URL}
          width="100%"
          loop={true}
          height="100%"
          playing={true}
          controls={false}
          config={{ playerVars: { disablekb: 1, modestbranding: 1, rel: 0, fs: 0, controls: 0 } }}
        />
      </div>
    </section>
  );
};

export default HeroSection;
