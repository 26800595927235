import Container from '../../Container';
import { css } from '@emotion/react';
import {
  borderRadius,
  Breakpoint,
  colorPalette,
  commonStyles,
  fontFamily,
  fontSize,
  spacing,
} from '../../../config/styles';
import { StaticImage } from 'gatsby-plugin-image';
import { useMemo, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';

const styles = {
  section: css`
    background-color: ${colorPalette.lightBlue};
    padding: ${spacing['80']} 0;
  `,
  heading: css`
    ${commonStyles.h1}
    margin-bottom: ${spacing['120']};
    text-align: center;
  `,
  testimonialWrapperContent: css`
    display: grid;
    grid-template-areas:
      'avatar text'
      'avatar author';
    column-gap: ${spacing['20']};
    row-gap: ${spacing['20']};
    ${Breakpoint.mobile} {
      grid-template-areas:
        'avatar author'
        'text text';
      row-gap: ${spacing['5']};
      column-gap: ${spacing['10']};
    }
  `,
  testimonialWrapper: css`
    background-color: ${colorPalette.white};
    border-radius: ${borderRadius['30']};
    padding: ${spacing['60']};

    ${Breakpoint.mobile} {
      padding: ${spacing['20']};
    }
  `,
  testimonialText: css`
    ${commonStyles.basicText}
    font-size: ${fontSize['30']};
    text-align: right;
    grid-area: text;

    ${Breakpoint.mobile} {
      text-align: left;
    }
  `,
  author: css`
    color: ${colorPalette.main};
    text-align: right;
    font-size: ${fontSize['28']};
    font-family: ${fontFamily.elido};
    margin-top: ${spacing['20']};
    grid-area: author;

    ${Breakpoint.mobile} {
      text-align: left;
      margin-top: 0;
    }
  `,
  avatar: css`
    width: 194px;
    height: 194px;
    grid-area: avatar;
    position: relative;
    border-radius: ${borderRadius['circle']};
    overflow: hidden;
    /* Needed to enable rounded corners on safari */
    transform: translateZ(0);

    ${Breakpoint.mobile} {
      width: 100px;
      height: 100px;
      top: -30px;
      left: -30px;
    }
  `,

  arrowButton: css`
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 0;
    > div {
      width: 45px;
      height: 45px;
      ${Breakpoint.mobile} {
        width: 30px;
        height: 30px;
      }
    }
  `,

  testimonialsBlock: css`
    display: flex;
    gap: ${spacing['10']};
  `,
};

const TestimonialsSection = () => {
  const t = useTranslation();
  const testimonials = useMemo(() => {
    return [
      {
        renderPicture: () => {
          return (
            <StaticImage src={'../../../images/testimonials/siro.jpeg'} alt={t('testimonial_section_user_1_title')} />
          );
        },
        text: t('testimonial_section_user_1_text'),
        author: t('testimonial_section_user_1_title'),
      },
      {
        renderPicture: () => {
          return (
            <StaticImage src={'../../../images/testimonials/pascal.jpeg'} alt={t('testimonial_section_user_2_title')} />
          );
        },
        text: t('testimonial_section_user_2_text'),
        author: t('testimonial_section_user_2_title'),
      },

      {
        renderPicture: () => {
          return (
            <StaticImage src={'../../../images/testimonials/sandra.jpeg'} alt={t('testimonial_section_user_3_title')} />
          );
        },
        text: t('testimonial_section_user_3_text'),
        author: t('testimonial_section_user_3_title'),
      },
    ];
  }, [t]);

  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0);
  const currentTestimonial = testimonials[activeTestimonialIndex];
  const onBack = () => {
    if (activeTestimonialIndex - 1 < 0) {
      setActiveTestimonialIndex(testimonials.length - 1);
    } else {
      setActiveTestimonialIndex(activeTestimonialIndex - 1);
    }
  };
  const onForward = () => {
    if (activeTestimonialIndex + 1 > testimonials.length - 1) {
      setActiveTestimonialIndex(0);
    } else {
      setActiveTestimonialIndex(activeTestimonialIndex + 1);
    }
  };
  return (
    <section css={styles.section}>
      <Container>
        <h1 css={styles.heading}>{t('testimonials_section_title')}</h1>

        <div css={styles.testimonialsBlock}>
          <button css={styles.arrowButton} type="button" onClick={onBack}>
            <StaticImage src={'../../../images/left-arrow-white.png'} alt={t('common_btn_prev')} />
          </button>
          <div css={styles.testimonialWrapper}>
            <div css={styles.testimonialWrapperContent}>
              <div css={styles.avatar}>{currentTestimonial.renderPicture()}</div>
              <div css={styles.testimonialText}>{currentTestimonial.text}</div>
              <div css={styles.author}>{currentTestimonial.author}</div>
            </div>
          </div>
          <button css={styles.arrowButton} type="button" onClick={onForward}>
            <StaticImage src={'../../../images/right-arrow-white.png'} alt={t('common_btn_next')} />
          </button>
        </div>
      </Container>
    </section>
  );
};

export default TestimonialsSection;
