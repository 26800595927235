import { css } from '@emotion/react';
import {
  borderRadius,
  Breakpoint,
  colorPalette,
  commonStyles,
  fontFamily,
  fontSize,
  spacing,
} from '../../../config/styles';
import { StaticImage } from 'gatsby-plugin-image';
import Container from '../../Container';
import ButtonLink from '../../ButtonLink';
import { APP_DOWNLOAD_LINK, Sections } from '../../../config/constants';
import { useTranslation } from '../../../hooks/useTranslation';
import { useMemo } from 'react';

const styles = {
  section: css`
    background-color: ${colorPalette.white};
    padding: ${spacing['140']} 0;
  `,

  itemBlock: (isEven: boolean) => css`
    display: flex;
    align-items: center;
    padding-bottom: 120px;
    position: relative;
    ${isEven &&
    css`
      flex-direction: row-reverse;
    `}

    &:first-child {
      &:before {
        height: 50%;
        bottom: 0;
      }
    }
    &:last-child {
      padding-bottom: 0px;
      &:before {
        top: 0;
      }
    }

    &:before {
      content: ' ';
      background-color: ${colorPalette.lightBlue};
      width: 20px;
      position: absolute;
      height: 100%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 0;
    }
  `,
  itemContent: (isEven: boolean) => css`
    background-color: ${colorPalette.lightBlue};

    color: ${colorPalette.main};
    display: flex;
    gap: ${spacing['20']};

    ${isEven
      ? css`
          border-top-right-radius: ${borderRadius['30']};
          border-bottom-right-radius: ${borderRadius['30']};
          flex-direction: row-reverse;
          padding-left: ${spacing['40']};
          text-align: left;
        `
      : css`
          border-top-left-radius: ${borderRadius['30']};
          border-bottom-left-radius: ${borderRadius['30']};
          padding-right: ${spacing['40']};
          text-align: right;
        `}
    ${Breakpoint.mobile} {
      border-radius: ${borderRadius['30']};
    }
  `,

  imageWrapper: css`
    > div {
      width: 300px;
    }
    ${Breakpoint.mobile} {
      display: none;
    }
  `,

  headline: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['48']};
    margin: 0;
    padding-bottom: ${spacing['20']};
  `,
  text: css`
    ${commonStyles.basicText}
    padding-bottom: ${spacing['20']};
  `,
  numberDisplay: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['300']};
  `,
  textWrapper: css`
    position: relative;
    top: -30px;
  `,
  itemsList: css`
    display: flex;
    flex-direction: column;
  `,

  arrowWrapper: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  arrowRightPart: css`
    width: 19px;
    height: 50px;
    background: ${colorPalette.lightBlue};
    transform: rotate(45deg);
    position: absolute;
    border-radius: ${borderRadius['30']};
    transform-origin: bottom right;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 5px;
  `,
  arrowLeftPart: css`
    width: 19px;
    height: 50px;
    background: ${colorPalette.lightBlue};
    transform: rotate(-45deg);
    position: absolute;
    border-radius: ${borderRadius['30']};
    transform-origin: bottom left;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 5px;
  `,
  arrowCenterPart: css`
    width: 20px;
    height: 120px;
    background-color: ${colorPalette.lightBlue};
    border-bottom-right-radius: ${borderRadius['30']};
    border-bottom-left-radius: ${borderRadius['30']};
  `,
  cta: css`
    text-align: center;
    margin-top: ${spacing['20']};
  `,
};

const HowToSection = () => {
  const t = useTranslation();

  const howToItems = useMemo(() => {
    return [
      {
        headline: t('how_to_step_1_headline'),
        text: t('how_to_step_1_text'),
        renderPicture: () => {
          return <StaticImage src="../../../images/how-to/how-to-1.png" alt={t('how_to_step_1_headline')} />;
        },
      },
      {
        headline: t('how_to_step_2_headline'),
        text: t('how_to_step_2_text'),
        renderPicture: () => {
          return <StaticImage src="../../../images/how-to/how-to-2.png" alt={t('how_to_step_2_headline')} />;
        },
      },
      {
        headline: t('how_to_step_3_headline'),
        text: t('how_to_step_3_text'),
        renderPicture: () => {
          return <StaticImage src="../../../images/how-to/how-to-3.png" alt={t('how_to_step_3_headline')} />;
        },
      },
      {
        headline: t('how_to_step_4_headline'),
        text: t('how_to_step_4_text'),
        renderPicture: () => {
          return <StaticImage src="../../../images/how-to/how-to-4.png" alt={t('how_to_step_4_headline')} />;
        },
      },
    ];
  }, [t]);

  return (
    <section css={styles.section} id={Sections.howTo}>
      <Container>
        <div css={styles.itemsList}>
          {howToItems.map((item, index) => {
            const isEven = index % 2 !== 0;
            return (
              <div css={styles.itemBlock(isEven)} key={index}>
                <div css={styles.itemContent(isEven)}>
                  <div css={styles.numberDisplay}>{index + 1}</div>
                  <div css={styles.textWrapper}>
                    <h4 css={styles.headline}>{item.headline}</h4>
                    <div css={styles.text}>{item.text}</div>
                  </div>
                </div>
                <div css={styles.imageWrapper}>{item.renderPicture()}</div>
              </div>
            );
          })}
        </div>
        <div css={styles.arrowWrapper}>
          <div css={styles.arrowRightPart} />
          <div css={styles.arrowLeftPart} />
          <div css={styles.arrowCenterPart} />
        </div>
        <div css={styles.cta}>
          <ButtonLink label={t('common_btn_download_app')} href={APP_DOWNLOAD_LINK} internal={false} />
        </div>
      </Container>
    </section>
  );
};

export default HowToSection;
