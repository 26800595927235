import { css } from '@emotion/react';
import {
  Breakpoint,
  colorPalette,
  commonStyles,
  fontFamily,
  fontSize,
  fontWeight,
  spacing,
} from '../../../config/styles';
import Container from '../../Container';
import { useTranslation } from '../../../hooks/useTranslation';
import { useMemo } from 'react';

const styles = {
  section: css`
    background-color: ${colorPalette.main};
    padding: ${spacing['80']} 0;
    color: ${colorPalette.white};
  `,
  heading: css`
    ${commonStyles.h1}
    color: ${colorPalette.white};
    margin-bottom: ${spacing['120']};
    text-align: center;
    margin-bottom: ${spacing['80']};
  `,
  wrapper: css`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: ${spacing['40']};

    ${Breakpoint.tablet} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    ${Breakpoint.mobile} {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  `,
  sectionItem: css`
    display: flex;
    gap: ${spacing['20']};
    flex-direction: column;
    text-align: center;
  `,
  sectionItemTitle: css`
    font-family: ${fontFamily.elido};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['96']};
  `,
  sectionItemText: css`
    font-family: ${fontFamily.elido};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['40']};
  `,
};

const OpportunitySection = () => {
  const t = useTranslation();
  const sections = useMemo(
    () => [
      { title: '1', text: t('opportunity_section_item_1') },
      { title: '2', text: t('opportunity_section_item_2') },
      { title: '3', text: t('opportunity_section_item_3') },
      { title: '4', text: t('opportunity_section_item_4') },
    ],
    [t],
  );

  return (
    <section css={styles.section}>
      <Container>
        <h1 css={styles.heading}>{t('opportunity_section_title')}</h1>
        <div css={styles.wrapper}>
          {sections.map((item, index) => {
            return (
              <div css={styles.sectionItem} key={index}>
                <div css={styles.sectionItemTitle}>{item.title}</div>
                <div css={styles.sectionItemText}>{item.text}</div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default OpportunitySection;
