import { css } from '@emotion/react';
import { Breakpoint, colorPalette, fontFamily, fontSize, fontWeight, spacing } from '../../config/styles';
import Container from '../Container';
import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } from '../../config/constants';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from '../../hooks/useTranslation';
import { Link } from 'gatsby';
import TermsFile from '../../files/terms.pdf';
import SplingLogo from '../../images/splint-logo.svg';

const styles = {
  section: css`
    background-color: ${colorPalette.main};
    color: ${colorPalette.white};
    padding-top: ${spacing['40']};
  `,
  socials: css`
    display: flex;
    gap: ${spacing['10']};
    margin-bottom: ${spacing['50']};
  }
  `,
  socialLink: css`
    width: 60px;
    height: 60px;
    ${Breakpoint.tablet} {
      width: 40px;
      height: 40px;
    }
  `,
  sectionTitle: css`
    font-family: ${fontFamily.elido};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['30']};
    margin-bottom: ${spacing['20']};
  `,
  sectionText: css`
    font-size: ${fontSize['30']};
    font-family: ${fontFamily.elidoBook};
    line-height: 1.2;
  `,
  container: css`
    display: flex;
    gap: ${spacing['50']};
    padding-bottom: ${spacing['50']};

    ${Breakpoint.tablet} {
      flex-direction: column;
    }
  `,
  rightColumn: css`
    flex: 1;
    text-align: right;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    ${Breakpoint.tablet} {
      align-items: flex-start;
    }

    ${Breakpoint.tablet} {
      text-align: left;
    }
  `,
  leftColumn: css`
    flex: 1;
    text-align: left;
  `,
  bottomBar: css`
    font-size: ${fontSize['24']};
    font-family: ${fontFamily.elidoBook};
    color: ${colorPalette.white};
    display: flex;
    justify-content: space-between;
    padding-bottom: ${spacing['30']};
    gap: ${spacing['20']};

    ${Breakpoint.tablet} {
      flex-direction: column;
    }

    a {
      color: ${colorPalette.white};
      text-decoration: none;
    }
  `,
  bottomLinks: css`
    display: flex;
    gap: ${spacing['20']};
    ${Breakpoint.tablet} {
      flex-direction: column;
    }
  `,
  langLinks: css`
    font-family: ${fontFamily.elido};
  `,
  logo: css`
    max-width: 400px;
    width: 100%;
    margin-bottom: ${spacing['10']};
    svg {
      path {
        fill: ${colorPalette.white};
      }
    }
  `,
};
const Footer = () => {
  const t = useTranslation();
  return (
    <footer css={styles.section}>
      <Container>
        <div css={styles.container}>
          <div css={styles.leftColumn}>
            <div css={styles.sectionTitle}>{t('footer_follow_text')}</div>
            <div css={styles.socials}>
              <a href={FACEBOOK_URL} css={styles.socialLink} target="_blank" rel="noreferrer">
                <StaticImage src={'../../images/social/facebook.png'} alt="Facebook" />
              </a>
              <a href={INSTAGRAM_URL} css={styles.socialLink} target="_blank" rel="noreferrer">
                <StaticImage src={'../../images/social/instagram.png'} alt="Instagram" />
              </a>
              <a href={LINKEDIN_URL} css={styles.socialLink} target="_blank" rel="noreferrer">
                <StaticImage src={'../../images/social/linkedin.png'} alt="Linkedin" />
              </a>
            </div>
            <div>
              <div css={styles.sectionTitle}>{t('footer_contact_text')}</div>
              <div css={styles.sectionText}>
                info@splintinvest.com
                <br />
                MARK Investment Holding AG <br /> c/o lic. iur. Jost M. Frigo <br /> Unter Altstadt 30
                <br />
                6300 Zug
                <br />
                Schweiz
              </div>
            </div>
          </div>
          <div css={styles.rightColumn}>
            <div css={styles.logo}>
              <SplingLogo />
            </div>
            <div css={styles.sectionText}>{t('footer_text')}</div>
          </div>
        </div>
        <div css={styles.bottomBar}>
          <div>{t('footer_all_rights_reserved')}</div>
          <div css={styles.bottomLinks}>
            <a href={TermsFile} target="_blank" rel="noreferrer">
              {t('footer_terms')}
            </a>
            <Link css={styles.langLinks} to="/en/">
              {t('footer_lang_english')}
            </Link>
            <Link css={styles.langLinks} to="/">
              {t('footer_lang_german')}
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
