import { css } from '@emotion/react';
import { colorPalette, commonStyles, fontFamily, fontSize, spacing } from '../../../config/styles';
import Container from '../../Container';
import { useTranslation } from '../../../hooks/useTranslation';

const styles = {
  section: css`
    background-color: ${colorPalette.lightBlue};
    padding: ${spacing['90']} 0;
  `,
  heading: css`
    ${commonStyles.h1}
    margin-bottom: ${spacing['120']};
  `,
  quote: css`
    ${commonStyles.basicText}
  `,
  author: css`
    text-align: right;
    font-family: ${fontFamily.elido};
    color: ${colorPalette.main};
    font-size: ${fontSize['36']};
    margin-top: ${spacing['20']};
  `,
};

const SloganSection = () => {
  const t = useTranslation();
  return (
    <section css={styles.section}>
      <Container>
        <h1 css={styles.heading}>{t('slogan_section_heading')}</h1>
        <div css={styles.quote}>{t('slogan_section_quote')}</div>
        <div css={styles.author}>- {t('slogan_section_author')}</div>
      </Container>
    </section>
  );
};

export default SloganSection;
