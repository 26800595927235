import Container from '../../Container';
import { css } from '@emotion/react';
import { Breakpoint, colorPalette, commonStyles, fontFamily, spacing } from '../../../config/styles';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from '../../../hooks/useTranslation';

const styles = {
  section: css`
    background-color: ${colorPalette.white};
    padding: ${spacing['120']} 0 0;
    ${Breakpoint.mobile} {
      padding: ${spacing['90']} 0;
    }
  `,
  heading: css`
    ${commonStyles.h1}
    margin-bottom: ${spacing['80']};
  `,
  text: css`
    ${commonStyles.basicText}
    display: block;
    width: 70%;

    ${Breakpoint.mobile} {
      width: 100%;
    }

    &:nth-child(2) {
      align-self: flex-end;
      text-align: right;
    }
  `,
  textsBlock: css`
    font-family: ${fontFamily.elidoBook};
    display: flex;
    flex-direction: column;
    gap: ${spacing['40']};
  `,
  clockWrapper: css`
    position: relative;
    width: 100%;
    text-align: right;
    bottom: -70px;
    ${Breakpoint.mobile} {
      display: none;
    }
  `,
};

const AboutUsSection = () => {
  const t = useTranslation();

  return (
    <section css={styles.section}>
      <Container>
        <h1 css={styles.heading}>{t('about_us_section_title')}</h1>
        <div css={styles.textsBlock}>
          <div css={styles.text}>{t('about_us_section_text_1')}</div>
          <div css={styles.text}>{t('about_us_section_text_2')}</div>
        </div>
        <div css={styles.clockWrapper}>
          <StaticImage src={'../../../images/clock.png'} alt={t('about_us_section_title')} height={300} />
        </div>
      </Container>
    </section>
  );
};

export default AboutUsSection;
