import Container from '../../Container';
import { css } from '@emotion/react';
import { Breakpoint, colorPalette, commonStyles, fontFamily, fontSize, spacing } from '../../../config/styles';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from '../../../hooks/useTranslation';
import { Sections } from '../../../config/constants';

const styles = {
  section: css`
    background-color: ${colorPalette.main};
    padding-bottom: ${spacing['60']};
  `,
  heading: css`
    ${commonStyles.h1}
    color: ${colorPalette.white};
    grid-area: title;
    padding-top: ${spacing['120']};
    align-self: flex-end;
  `,
  wrapper: css`
    display: grid;
    column-gap: ${spacing['20']};
    row-gap: ${spacing['90']};
    grid-template-columns: 300px auto;
    grid-template-areas:
      'icon title'
      'second-icon text';

    ${Breakpoint.tablet} {
      grid-template-columns: 120px auto;
    }

    ${Breakpoint.mobile} {
      grid-template-columns: 60px auto;
      grid-template-areas:
        'icon title'
        'text text';
    }
  `,
  textWrapper: css`
    grid-area: text;
    display: flex;
    gap: ${spacing['40']};
    ${Breakpoint.mobile} {
      flex-direction: column;
    }
  `,
  icon: css`
    background-color: ${colorPalette.white};
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    width: 120px;
    height: 100%;
    min-height: 300px;
    top: -1px;
    position: relative;
  `,

  iconWrapper: css`
    grid-area: icon;
    display: flex;
    justify-content: flex-end;
  `,

  itemBlock: css`
    color: ${colorPalette.white};
    display: flex;
    gap: ${spacing['20']};
  `,

  itemTitle: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['48']};
    margin: 0;
  `,
  itemTitleNumber: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['72']};
    margin: 0;
  `,

  itemText: css`
    ${commonStyles.basicText}
    color: ${colorPalette.white};
  `,
  bottleImage: css`
    position: absolute;
    bottom: -100px;
  `,
  bottleWrapper: css`
    grid-area: second-icon;
    position: relative;
    ${Breakpoint.mobile} {
      display: none;
    }
  `,
};

const WhatIsSplintSection = () => {
  const t = useTranslation();

  return (
    <section css={styles.section} id={Sections.whatIsSplint}>
      <Container>
        <div css={styles.wrapper}>
          <div css={styles.bottleWrapper}>
            <div css={styles.bottleImage}>
              <StaticImage src={'../../../images/bottle.png'} alt={t('what_is_splint_section_title')} height={300} />
            </div>
          </div>
          <div css={styles.iconWrapper}>
            <div css={styles.icon} />
          </div>
          <h1 css={styles.heading}>{t('what_is_splint_section_title')}</h1>
          <div css={styles.textWrapper}>
            <div css={styles.itemBlock}>
              <h3 css={styles.itemTitleNumber}>1</h3>
              <div>
                <h3 css={styles.itemTitle}>{t('what_is_splint_section_col_1_title')}</h3>
                <div css={styles.itemText}>{t('what_is_splint_section_col_1_text')}</div>
              </div>
            </div>
            <div css={styles.itemBlock}>
              <h3 css={styles.itemTitleNumber}>2</h3>
              <div>
                <h3 css={styles.itemTitle}>{t('what_is_splint_section_col_2_title')}</h3>
                <div css={styles.itemText}>{t('what_is_splint_section_col_2_text')}</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WhatIsSplintSection;
