import HeroSection from './Sections/HeroSection';
import SloganSection from './Sections/SloganSection';
import HowToSection from './Sections/HowTo';
import WhatIsSplintSection from './Sections/WhatIsSplintSection';
import AboutUsSection from './Sections/AboutUsSection';
import Seo from '../Seo';
import OpportunitySection from './Sections/OpportunitySection';
import FAQSection from './Sections/FAQSection';
import TestimonialsSection from './Sections/TestimonialsSection';
import BlogSection from './Sections/BlogSection';
import Footer from '../Footer';
import { useTranslation } from '../../hooks/useTranslation';

const HomePage = () => {
  const t = useTranslation();

  return (
    <>
      <Seo title={t('home_page_title')} />
      <HeroSection />
      <SloganSection />
      <HowToSection />
      <WhatIsSplintSection />
      <AboutUsSection />
      <TestimonialsSection />
      <OpportunitySection />
      <BlogSection />
      <FAQSection />
      <Footer />
    </>
  );
};

export default HomePage;
